import { Variables } from './Helpers/variables';
import { GroupsService } from './Services/groups/groups.service';
import { CookieService } from 'ngx-cookie-service';
import { MenuItem, ConfirmationService } from 'primeng/api';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from './Services/users/users.service';
import * as CryptoJS from 'crypto-js';
import { OrganisationsService } from './Services/organisations/organisations.service';
import { UserPermissionService } from './Services/user-permission/user-permission.service';
import { KeycloakService } from 'keycloak-angular';
import { FileService } from './Services/file/file.service';
import { LanguageService } from './Services/language/language.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DataCache } from './Helpers/data-cache';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  weight = 'bold';
  user = 'bold';
  group = 'normal';
  usergroup = 'normal';
  title = 'GOEPLA-UI-USM';

  items: MenuItem[];
  load = false;

  isOrg = false;

  loading = false;

  groupids: any;

  uid = 0;

  appicon: HTMLLinkElement = document.querySelector('#appicon');

  infoText = 'Information';
  closeText = 'Close';

  isauth = false;

  userform: FormGroup;

  EmployeeId: number;
  Pin: string;

  oid = 0;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private cookieService: CookieService,
    private confirmationService: ConfirmationService,
    private usersService: UsersService,
    private orgService: OrganisationsService,
    private permService: UserPermissionService,
    private groupService: GroupsService,
    private userService: UsersService,
    private keycloakService: KeycloakService,
    private fileService: FileService,
    private language: LanguageService,
    private fb: FormBuilder,
    private http: HttpClient
  ) {
    if ('serviceWorker' in navigator) {
      caches.keys().then(function(cacheNames) {
        cacheNames.forEach(function(cacheName) {
          caches.delete(cacheName);
        });
      });
    }

    router.navigateByUrl('');
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(RegExp(/English|French/).exec(browserLang) ? browserLang : 'English');

    this.load = true;
    this.loading = true;
  }

  async ngOnInit() {
    this.userform = this.fb.group({
      empid: new FormControl('', Validators.required),
      pin: new FormControl('', Validators.required)
    });
    this.loading = true;

    if (localStorage.getItem('browser') === null || localStorage.getItem('browser') === 'false') {
      await this.showInfo('browser');
      return;
    }

    await this.setIcon();
    await this.getUser();
    this.loading = false;
  }

  login() {
    // do nothing
  }

  async getGroups() {
    this.loading = true;

    const v = new Variables();
    const va = localStorage.getItem('ui');
    const ui = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    const uid = +ui;
    let mygroups = '';
    // let username = ''
    let groupids = '';
    const gis = [];
    const usergroups = await this.usersService.getUserGroupsByGroupIdUserId(0, uid).toPromise()
    .catch(err => this.handleError(err));

    if (usergroups != null && usergroups.length > 0) {
      for (const val of usergroups) {
        groupids += val.groupId + ':';
        gis.push(val.groupId);
        const group = await this.groupService.getGroupById(val.groupId).toPromise()
                                              .catch(err => this.handleError(err));
        if (group != null && group.length > 0) {
          mygroups += group[0].groupName + ':';
        }
      }
    }

    const gs = CryptoJS.AES.encrypt(mygroups, v.pass);
    localStorage.setItem('mg', gs);

    const gds = JSON.stringify(gis);
    const gids = CryptoJS.AES.encrypt(gds, v.pass);
    localStorage.setItem('gi', gids);

    const user = await this.usersService.getUserInfo(uid, 0, ' ', ' ').toPromise()
    .catch(err => this.handleError(err));

    // username = user?.firstName + ' ' + user?.lastName

    this.groupids = groupids;

    await this.getPermissions(user.organisationName, mygroups);

    this.loading = false;
  }

  async getPermissions(org, mygroups) {
    this.loading = true;
    const permissions = await this.returnPermissions();

    if (org === 'ORG_SUPER_ADMIN' || mygroups.includes('Administrators-Full_Group')) {
      permissions.push('USM.Administration.Full');
      this.isOrg = true;
    }

    const v = new Variables();
    const p = CryptoJS.AES.encrypt(JSON.stringify(permissions), v.pass).toString();
    localStorage.setItem('ups', p);
    await this.buildMenu(permissions);
  }

  async returnPermissions() {
    const permissions = [];

    const groupids = this.groupids;
    if (groupids != null) {
      const gids = groupids.split(':');
      if (gids !== undefined && gids !== null && gids.length > 0) {
        for (const id of gids) {
          if (id !== '') {
            const perms = await this.permService.getUserPermissions(0, id, ' ').toPromise()
            .catch(err => this.handleError(err));
            if (perms != null && perms.length > 0) {
              for (const perm of perms) {
                permissions.push(perm.perm);
              }
            }
          }
        }
      }
    }

    return permissions;
  }

  async buildMenu(perm) {
    this.items = [];
    if (perm != null && perm.length > 0) {
      let isuser = false;
      if (perm.includes('USM.Section.Users') || perm.includes('USM.Administration.Full')) {
        isuser = true;
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.USERS').subscribe((resp1: string) => {
          this.items.push({label: resp1, routerLink: ['/users']});
        });
      }
      if (perm.includes('USM.Section.Groups') || perm.includes('USM.Administration.Full')) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.GROUPS').subscribe((resp2: string) => {
          this.items.push({label: resp2, routerLink: ['/groups']});
        });
      }
      if (perm.includes('USM.Section.UserGroups') || perm.includes('USM.Administration.Full')) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.GROUPUSERS').subscribe((resp3: string) => {
          this.items.push({label: resp3, routerLink: ['/usergroups']});
        });
      }
      if (perm.includes('USM.Section.ExternalUsers') || perm.includes('USM.Administration.Full')) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.EXTERNALUSERS').subscribe((resp4: string) => {
          this.items.push({label: resp4, routerLink: ['/externalusers']});
        });
      }
      if (perm.includes('USM.Section.Roles') || perm.includes('USM.Administration.Full')) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.ROLES').subscribe((resp5: string) => {
          this.items.push({label: resp5, routerLink: ['/roles']});
        });
      }
      if (perm.includes('USM.Section.Permissions') || perm.includes('USM.Administration.Full')) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.PERMISSIONS').subscribe((resp6: string) => {
          this.items.push({label: resp6, routerLink: ['/permissions']});
        });
      }
      if (this.isOrg === true) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.ORGANISATIONS').subscribe((resp7: string) => {
          this.items.push({label: resp7, routerLink: ['/organisations']});
        });
      }
      if (perm.includes('USM.Section.Departments') || perm.includes('USM.Administration.Full')) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.DEPARTMENTS').subscribe((resp8: string) => {
          this.items.push({label: resp8, routerLink: ['/departments']});
        });
      }

      if (this.items.length === 0) {
        this.load = false;
        this.router.navigateByUrl('/unauthorizeduser');
      }

      if (isuser === true) {
        this.router.navigateByUrl('/users');
      }
    } else {
      this.load = false;
      this.router.navigateByUrl('/unauthorizeduser');
    }
  }

  show(event) {
    localStorage.setItem('route', this.router.url);
  }

  async setIcon() {
    this.loading = true;
    const bucket = environment.bucket; // 'usm-bucket'
    const file = await this.fileService.getFiles(1, 1, ' ', 'usm-icon', bucket, ' ', ' ')
    .toPromise().catch(err => this.handleError(err));
    if (file !== undefined && file !== null && file.length > 0) {
      const furl = await this.fileService.getFile(bucket, file[0].fileId)
      .toPromise().catch(err => this.handleError(err));
      if (furl !== undefined && furl !== null && furl.url !== undefined && furl.url !== null) {
        this.appicon.href = furl.url;
      }
    }
    this.loading = false;
  }

  async getUser() {
    this.loading = true;
    let eml = '';

    eml = (await this.keycloakService.loadUserProfile()).email;
    // eml = 'adminuser@email.com'
    const ress = await this.userService.getUserInfo(0, 0, eml, null).toPromise().catch(err => this.handleError(err));

    if (ress !== undefined && ress !== null && ress.userId > 0) {
      const v = new Variables();
      const oid = CryptoJS.AES.encrypt(JSON.stringify(ress.organisationId), v.pass).toString();
      const uid = CryptoJS.AES.encrypt(JSON.stringify(ress.userId), v.pass).toString();
      localStorage.setItem('oi', oid);
      localStorage.setItem('ui', uid);

      await this.showInfo('null');
      await this.getGroups();

      this.cacheData();
    } else {
      this.loading = false;

      await this.showInfo('user');
    }
  }

  async showInfo(info) {
    this.loading = true;
    if (this.keycloakService === undefined || this.keycloakService === null
      || this.keycloakService.getKeycloakInstance() === undefined
      || this.keycloakService.getKeycloakInstance() === null) {
      if (info === 'browser') {
        this.load = false;
        localStorage.setItem('message', 'Browser doesn\'t fulfill the browser requirements:' +
        ' The Browser requirements are: Browser type = Chrome, Minimum browser version = 80.0.3987.122');
        localStorage.setItem('info', this.infoText);
        localStorage.setItem('but', this.closeText);
        this.router.navigateByUrl('xxxxx');
      } else if (info === 'user') {
        localStorage.setItem('message', 'USER NOT FOUND IN USM. User can\'t continue using the application !!!!!');
        localStorage.setItem('info', this.infoText);
        localStorage.setItem('but', this.closeText);
        this.router.navigateByUrl('xxxxx');
      }
      this.loading = false;
      return;
    } else {
      console.log('');
      if (localStorage.getItem('language') === null) {
        // Replace the user id with the users user id later
        const lang = await this.language.getLanguage(1).toPromise().catch(err => this.handleError(err));
        if (lang.userId > 0) {
          localStorage.setItem('language', lang.lang);

          this.translate.addLangs(['English', 'French']);
          this.translate.setDefaultLang('English');

          let browserLang = this.translate.getBrowserLang();
          if (localStorage.getItem('language') !== null) {
            browserLang = localStorage.getItem('language');
          }
          this.translate.use(RegExp(/English|French/).exec(browserLang) ? browserLang : 'English');
        } else {
          this.translate.addLangs(['English', 'French']);
          this.translate.setDefaultLang('English');

          let browserLang = this.translate.getBrowserLang();
          if (localStorage.getItem('language') !== null) {
            browserLang = localStorage.getItem('language');
          }
          this.translate.use(RegExp(/English|French/).exec(browserLang) ? browserLang : 'English');
        }

        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.INFO').subscribe((resp) => {
          this.infoText = resp;
        });

        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.CLOSE').subscribe((resp) => {
          this.closeText = resp;
        });

        if (info === 'browser') {
          this.load = false;
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.BROWSERCHECK').subscribe((resp) => {
            localStorage.setItem('message', resp);
            localStorage.setItem('info', this.infoText);
            localStorage.setItem('but', this.closeText);
            this.router.navigateByUrl('xxxxx');
          });
        } else if (info === 'user') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.USERNOTFOUND').subscribe((resp) => {
            localStorage.setItem('message', resp);
            localStorage.setItem('info', this.infoText);
            localStorage.setItem('but', this.closeText);
            this.router.navigateByUrl('xxxxx');
          });
        }
      } else {
        console.log('');
        if (info === 'browser') {
          this.load = false;
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.BROWSERCHECK').subscribe((resp) => {
            localStorage.setItem('message', resp);
            localStorage.setItem('info', this.infoText);
            localStorage.setItem('but', this.closeText);
            this.router.navigateByUrl('xxxxx');
          });
        } else if (info === 'user') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.USERNOTFOUND').subscribe((resp) => {
            localStorage.setItem('message', resp);
            localStorage.setItem('info', this.infoText);
            localStorage.setItem('but', this.closeText);
            this.router.navigateByUrl('xxxxx');
          });
        }
      }
    }

    this.loading = false;
  }

  cacheData() {
    DataCache.loadData(this.http);
    // 500000 milliseconds = 8.33333333 mins
    // tslint:disable-next-line: only-arrow-functions
    setInterval(() => {
      DataCache.loadData(this.http);
    }, 500000);
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.loading = false;
    console.log(err);
  }

}
