import { Injectable } from '@angular/core';
import { Urls } from 'src/app/Helpers/Urls';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from 'src/app/Models/User';
import { tap, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserUpdater } from 'src/app/Models/KeyValue';
import { Variables } from 'src/app/Helpers/variables';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  url = new Urls();
  apiUrl: string;
  orgId: number;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
      const v = new Variables();
      const val = localStorage.getItem('oi');
      const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
      this.orgId = +oi;
  }

  getUsers() {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
    this.orgId = +oi;
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/users/' + this.orgId;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getUsersPageSize(page: number, size: number) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
    this.orgId = +oi;
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/users/' + this.orgId + '/' + page + '/' + size;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getUserInfo(uid, empid, email, phone) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/users/' + uid + '/' + empid + '/' + email + '/' + phone + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postUser(user: User): Observable<any> {
    // const body = JSON.stringify(inst);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.post(this.apiUrl + '/api/users', user, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  putUser(id: number, upt: UserUpdater): Observable<any> {
    // const body = JSON.stringify(inst);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.put(this.apiUrl + '/api/users/' + id, upt, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteUser(id: number): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.delete<any>(this.apiUrl + '/api/users/' + id, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  getUserRoles(uid: number, gid: number) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/userroles/' + uid + '/' + gid;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getUserGroupsByGroupIdUserId(gid: number, uid: number) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
    this.orgId = +oi;
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/usergroups/Get_ByUserId/' + this.orgId + '/' + gid + '/' + uid;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  validateUser(orgid: number, eid: number, email: string, phone: string, pass: string, pin: string) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/validateuser/' + orgid + '/' + eid + '/' + email + '/' + phone + '/' + pass + '/' + pin;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  private handleError(error: any) {
    // console.error('error', error);
    throw error;
    return error;
  }
}
